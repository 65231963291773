import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {device} from '../../../theme';
import SVG from 'react-inlinesvg';

export const TitleContainer = styled.header`
  display: inline-block;
  width: 100%;
  padding: 30px 0;
`;

const ServiceCard = styled(Link)`
  padding: 60px 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
  flex-basis: 20%;

  span {
    color: #111;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 1em;
  }

  p.subtitle {
    color: #555;
    font-weight: 300;
    font-size: .9em;
    text-align: center;
    margin: .5em auto;
    line-height: 1.8;
  }

  @media ${device.medium} {
    flex-basis: 50%;
    padding: 40px 5px;
  }

  @media ${device.small} {
    padding: 25px 5px;
  }
`;

// TODO Clean up color prop logic & handoff

const IconContainer = styled.div`
  width: 100px;
  height: 100px;
  margin: 5px;
  vertical-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(${props => props.r},${props => props.g},${props => props.b},${props => props.a});

  border: 5px solid white;
  border-radius: 0;

  box-shadow: 0 0 0 1px rgba(${props => props.r},${props => props.g},${props => props.b},${props => props.a});

  /* transition: border-color ease 0.5s; */
  transition: border ease 0.35s;

  :hover {
    border-color: rgba(${props => props.r},${props => props.g},${props => props.b},${props => props.a});
    border-width: 0;
  }
`;

const IconInline = styled(SVG)`
  width: 45px;
  height: auto;
  margin: 0 auto;

  path, g {
    fill: rgba(${props => props.r},${props => props.g},${props => props.b},${props => props.a});
  }
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 65px 0;

  @media ${device.small} {
    padding: 65px 25px;
  }
`;

export const ServiceListItem = ({ title, subtitle, slug, iconRaw, color, bgcolor }) => {

  return (
    <ServiceCard
      to={`/${slug}`}
    >
      <IconContainer
        r={bgcolor ? bgcolor.r : '17'} 
        g={bgcolor ? bgcolor.g : '17'}
        b={bgcolor ? bgcolor.b : '17'}
        a={bgcolor ? bgcolor.a : '1'}
      >
        <IconInline
          src={iconRaw}
          r={color ? color.r : '235'} 
          g={color ? color.g : '235'}
          b={color ? color.b : '235'}
          a={color ? color.a : '1'}
        />
      </IconContainer>
      <span>{title}</span>
      <p className="subtitle">{subtitle}</p>
    </ServiceCard>
  )
}