import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Particles from 'react-particles-js';
import Heading from '../../../components/shared/heading';
import Button from '../../../components/shared/button';
import clientConfig from '../../../../client-config';
import Img from 'gatsby-image';
import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity';

import {
  HeroArea,
  HeroBg,
  ContentTop,
  ContentWrapper,
  ContentBottom,
  ContentBottomInner,
} from './hero.stc';

const Hero = (props) => {
  const heroQueryData = useStaticQuery(graphql`
    query homeHeroDataQuery {
      sanityHome {
        _rawHero
        hero {
          heroCta {
            copy
            path {
              page {
                ... on SanityServices {
                  slug {
                    current
                  }
                }
                ... on SanityProjects {
                  slug {
                    current
                  }
                }
                ... on SanityOurProcess {
                  slug {
                    current
                  }
                }
                ... on SanityHome {
                  slug {
                    current
                  }
                }
                ... on SanityContact {
                  slug {
                    current
                  }
                }
                ... on SanityAbout {
                  slug {
                    current
                  }
                }
                ... on SanityProject {
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const heroData = heroQueryData.sanityHome._rawHero;
  const {
    heroTitle,
    heroTagline,
    heroLogo,
    heroImg,
    particleOptions,
  } = heroData;
  const {
    copy: heroLinkText,
    path: {
      page: {
        slug: { current: heroLink },
      },
    },
  } = heroQueryData.sanityHome.hero.heroCta;

  const imgFluid = getFluidGatsbyImage(
    heroImg.asset._ref,
    { maxWidth: 1920, maxHeight: 950, quality: 100 },
    clientConfig.sanity
  );
  const heroLogoImg = getFixedGatsbyImage(
    heroLogo.asset._ref,
    { width: 250, height: 250, qualilty: 100 },
    clientConfig.sanity
  );

  const particleOpt = {
    particles: {
      number: {
        value: particleOptions.particleCount,
      },
      size: {
        value: particleOptions.particleSize,
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: 'attract',
        },
      },
    },
  };

  const { descStyle, headingStyle } = props;
  return (
    <HeroArea>
      <HeroBg fluid={imgFluid} />
      <Particles className="particle" params={particleOpt} />
      <ContentWrapper>
        <ContentTop>
          <div className="">
            <Img fixed={heroLogoImg} />

            {heroTitle && <Heading {...headingStyle}>{heroTitle}</Heading>}

            {heroTagline && <Heading {...descStyle}>{heroTagline}</Heading>}

            {heroLink && heroLinkText && (
              <Button as={Link} to={heroLink} layout={2}>
                {heroLinkText} <i className="ti-arrow-right"></i>
              </Button>
            )}
          </div>
        </ContentTop>
        <ContentBottom>
          <div className="col-4 offset-1">
            <ContentBottomInner>
            </ContentBottomInner>
          </div>
        </ContentBottom>
      </ContentWrapper>
    </HeroArea>
  );
};

Hero.propTypes = {
  descStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
};

Hero.defaultProps = {
  descStyle: {
    as: 'h4',
    p: '5px 17px',
    color: '#ececec',
    fontweight: 300,
    fontSize: '1.2em',
    texttransform: 'uppercase',
    letterspacing: '10px',
    mt: '10px',
    mb: '10px',
    responsive: {
      medium: {
        fontweight: 600,
        letterspacing: '5px',
        fontSize: '1em'
      }
    }
  },
  headingStyle: {
    as: 'h1',
    color: '#ececec',
    fontSize: '4em',
    fontweight: 400,
    letterspacing: '10px',
    mb: '10px',
    responsive: {
      xlarge: {
        fontSize: '4em',
      },
      large: {
        fontSize: '4em',
        mb: '40px',
      },
      medium: {
        fontSize: '2.75em',
      },
    },
  },
  buttonStyle: {},
};

export default Hero;
