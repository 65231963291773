import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { AccentSection, AccentWrapper, ServiceListItem, ContentWrapper } from './accentRow.stc'

const AccentRow = () => {

  const accentRowData = useStaticQuery(graphql `
    query accentRowQuery {
      sanityHome {
        serviceAccentRow {
          image {
            asset {
              fluid(maxWidth: 1920, maxHeight: 950) {
                ...GatsbySanityImageFluid
              }
            }
          }
          serviceList {
            _id
            title
            slug {current}
            accentIcon {
              icon {
                asset {
                  url
                }
              }
              iconColor {
                rgb {
                  r
                  g
                  b
                  a
                }
              }
            }
          }
        }
      }
    }
  `);

  const {image, serviceList} = accentRowData.sanityHome.serviceAccentRow;

  return (
    <AccentSection>
      <AccentWrapper fluid={image.asset.fluid} backgroundColor={`#cccccc`}>
      
      <div className="overlay"></div>
      <ContentWrapper>
        {serviceList.map((data) => {
          return(
            <ServiceListItem
              key={data._id}
              title={data.title}
              slug={data.slug.current}
              iconRaw={data.accentIcon.icon.asset.url}
              color={data.accentIcon.iconColor && data.accentIcon.iconColor.rgb}
            />
          )
        })}
        </ContentWrapper>
      </AccentWrapper>
    </AccentSection>
  )
}
 
export default AccentRow