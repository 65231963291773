import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { device } from '../../../theme';
import BackgroundImage from 'gatsby-background-image';

export const AboutSectionWrap = styled.section`
  padding: 50px 0;
  @media ${device.medium} {
    padding: 70px 0;
  }
  @media ${device.small} {
    padding: 10vw 0;
  }
`;

export const AboutTitleContainer = styled.header`
  display: inline-block;
  width: 100%;
  padding: 30px 0;
`;

export const AboutContentContainer = styled.main`
  display: inline-flex;
  flex-flow: row wrap-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 6.25vw;
  @media ${device.medium} {
    padding: 25px 0;
  }
`;

export const AboutCopy = styled.article`
  width: 70%;
  padding: 0px 6.25%;
  text-align: justify;


  @media ${device.medium} {
    padding: 25px 8%;
    width: 100%;
  }

  @media ${device.small} {
    text-align-last: center;
  }
`;

const ImageWrap = styled.aside`
  width: 30%;
  height: 100%;
  order: -1;
  @media ${device.medium} {
    order: 2;
    padding-bottom: 50px;
    width: 100%;
  }
`;

const Image = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  min-height: 450px;
  @media ${device.medium} {
    min-height: 175px;
  }
`;

export const AboutAccent = ({ image }) => {
  return (
    <ImageWrap>
      <Image fluid={image} />
    </ImageWrap>
  );
};

export const CtaButton = styled(Link)`
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  display: inline-block;
  padding: 8px 38px;
  background: #111;
  color: #ececec;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1.8em;
  border-radius: 0;
  transition: all 0.3s;
  border: 1px solid rgba(17, 17, 17, 0);

  &:hover {
    border: 1px solid rgba(17, 17, 17, 1);
    background: #fff;
    color: #111;
  }

  &:visited {
    color: #ececec;
  }
`;
