import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SectionTitle from "../../../components/shared/section-title";
import PortableText from "../../../components/shared/portableText";
import {
  AboutSectionWrap,
  AboutTitleContainer,
  AboutContentContainer,
  AboutCopy,
  AboutAccent,
  CtaButton,
} from "./about.stc";

const About = () => {
  const aboutHomePageData = useStaticQuery(graphql`
    query aboutHomePageQuery {
      sanityHome {
        about {
          headline
          tagline
          img {
            asset {
              fluid(maxHeight: 950, maxWidth: 950) {
                ...GatsbySanityImageFluid
              }
            }
          }
          cta {
            copy
            path {
              page {
                ... on SanityServices {
                  slug {
                    current
                  }
                }
                ... on SanityProjects {
                  slug {
                    current
                  }
                }
                ... on SanityOurProcess {
                  slug {
                    current
                  }
                }
                ... on SanityHome {
                  slug {
                    current
                  }
                }
                ... on SanityContact {
                  slug {
                    current
                  }
                }
                ... on SanityAbout {
                  slug {
                    current
                  }
                }
                ... on SanityProject {
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
        _rawAbout
      }
    }
  `);

  const {
    headline,
    tagline: subhead,
    cta: {copy: ctaCopy},
    cta: {path: {page: {slug: {current: ctaPath}}}},
    img: {
      asset: { fluid: image },
    },
  } = aboutHomePageData.sanityHome.about;

  const { copy } = aboutHomePageData.sanityHome._rawAbout;

  return (
    <AboutSectionWrap>
      <AboutTitleContainer>
        <SectionTitle title={headline} subtitle={subhead} />
      </AboutTitleContainer>
      <AboutContentContainer>
        <AboutCopy>
          <PortableText blocks={copy} />
          <CtaButton
            to={ctaPath}
            className={'cta'}
          >
            {ctaCopy}
          </CtaButton>
        </AboutCopy>
        <AboutAccent image={image} />
      </AboutContentContainer>
    </AboutSectionWrap>
  );
};

export default About;
