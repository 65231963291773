import React from 'react';
import styled from 'styled-components';
import { device } from '../../../theme';
import SVG from 'react-inlinesvg';
import { Link } from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

export const SectionWrap = styled.section`
  padding: 50px 0;
  background-color: #f8f8f8;

  @media ${device.small} {
    padding: 10vw 0;
  }
`;

export const TitleContainer = styled.header`
  display: inline-block;
  width: 100%;
  padding: 30px 0;
`;

export const ContentContainer = styled.main`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px 6.25vw;
  @media ${device.medium} {
    padding: 25px 0;
  }
`;

export const CtaButton = styled(Link)`
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  display: inline-block;
  padding: 8px 38px;
  background: #111;
  color: #ececec;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1.8em;
  border-radius: 0;
  transition: all 0.3s;
  border: 1px solid rgba(17, 17, 17, 0);

  &:hover {
    border: 1px solid rgba(17, 17, 17, 1);
    background: #fff;
    color: #111;
  }

  &:visited {
    color: #ececec;
    &:hover {
      color: #111;
    }
  }
`;

const ItemWrap = styled.div`
  width: calc(100% / 3);
  padding: 50px 6.25%;
  text-align: left;
  position: relative;

  @media ${device.large} {
    width: 50%;
  }
  @media ${device.small} {
    width: 100%;
  }

  h4 {
    margin: 0;
    padding-bottom: 5px;
    padding-left: 30px;
    font-size: 1.07em;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.1;
  }

  h5 {
    margin: 0;
    padding-left: 30px;
    font-size: 0.895em;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 400;
  }

  p {
    margin: 0;
    padding-top: 20px;
    padding-left: 30px;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.75;
    color: #444;
    z-index: 5 !important;
  }
`;

const BgNumber = styled.span`
  display: inline-block;
  position: absolute;
  top: -240px;
  left: -30px;
  color: rgba(204, 204, 220, 0);
  font-weight: lighter;
  font-size: 165px;
  z-index: 0;
`;

const Icon = styled(SVG)``;

export const ProcessItem = ({ number, title, subtitle, copy, icon }) => {
  return (
    <ItemWrap>
      <h4>{title}</h4>
      {subtitle && <h5>{subtitle}</h5>}
      {icon && <Icon src={icon} />}
      <p>{copy}</p>
      <Controller>
        <Scene duration="600" offset="-400">
          <Timeline>
            <Tween
              to={{
                top: '-200px',
                left: '-27px',
                color: 'rgba(204, 204, 220, 0.2)',
                ease: 'power3.out',
              }}
            >
              <BgNumber>{number}</BgNumber>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </ItemWrap>
  );
};
