import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SectionTitle from '../../../components/shared/section-title';
import {
  SectionWrap,
  TitleContainer,
  ContentContainer,
  ProcessItem,
  CtaButton,
} from './process.stc';

const Process = () => {
  const homeProcessSectionData = useStaticQuery(graphql`
    query homeProcessSectionQuery {
      sanityHome {
        processHeadline
        processSubhead
        processCta {
          copy
          path {
            page {
              ... on SanityServices {
                slug {
                  current
                }
              }
              ... on SanityProjects {
                slug {
                  current
                }
              }
              ... on SanityOurProcess {
                slug {
                  current
                }
              }
              ... on SanityHome {
                slug {
                  current
                }
              }
              ... on SanityContact {
                slug {
                  current
                }
              }
              ... on SanityAbout {
                slug {
                  current
                }
              }
              ... on SanityProject {
                slug {
                  current
                }
              }
              ... on SanityService {
                slug {
                  current
                }
              }
            }
          }
        }
      }
      sanityOurProcess {
        details {
          _key
          title
          subtitle
          summary
        }
      }
    }
  `);

  const {
    processHeadline: title,
    processSubhead: subhead,
    processCta: { copy: ctaCopy, path },
  } = homeProcessSectionData.sanityHome;
  const { details: processItems } = homeProcessSectionData.sanityOurProcess;

  return (
    <SectionWrap>
      <TitleContainer>
        <SectionTitle title={title} subtitle={subhead} />
      </TitleContainer>
      <ContentContainer id="processTrigger">
        {processItems.map((data, i) => {
          return (
            <ProcessItem
              key={data._key}
              number={(i + 1).toString().padStart(2, '0')}
              title={data.title}
              subtitle={data.subtitle}
              copy={data.summary}
            />
          );
        })}
        <CtaButton to={path.page.slug.current}>{ctaCopy}</CtaButton>
      </ContentContainer>
    </SectionWrap>
  );
};

export default Process;
